import * as React from "react"
import {graphql} from "gatsby";
import Img from "gatsby-image";
import {Helmet} from "react-helmet";

import siteSettings from "../constants/site-settings";

import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import ContactFormComponent from "../components/contact-form";

import '../styles/service-area.scss'


const ServiceAreaPage = ({data, pageContext}) => {

  const fluidTopImgSource = [
    data.top.childImageSharp.fluid,
  ];

  const paymentImgSource = [
    data.payment.childImageSharp.fluid,
  ];

  const starGoogleImgSource = [
    data.starGoogle.childImageSharp.fluid,
  ];

  const yelpImgSource = [
    data.yelp.childImageSharp.fluid,
  ];

  const googleGuaranteedImgSource = [
    data.googleGuaranteed.childImageSharp.fluid,
  ];

  const metaTitle = `${siteSettings.siteTitle} - Local Service`;
  const metaDescription = `Local garage door repair and garage door installation. Garage door spring and cable repair. Trusted garage door company. ${siteSettings.phoneNumberPretty}`;

  return (
    <div className="w-full">
      <Helmet>
        <title>{pageContext.pageTitle}</title>
        <meta charSet="utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover"/>
        <meta name="description" content={pageContext.description}/>
        <meta name="keywords" content={pageContext.keywords}/>

        <meta http-equiv="refresh" content="0;url=https://www.gdbgaragedoorservice.com/"/>

        <meta property="og:site_name" content={metaTitle}/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content={siteSettings.siteTitle}/>
        <meta property="og:description" content={siteSettings.siteDescription}/>
        <meta property="og:url" content={siteSettings.siteUrl}/>
        <meta property="og:type" content="Landing page"/>

        <meta property="og:image" content={siteSettings.image}/>
        <meta property="og:image:width" content={siteSettings.shareImageWidth}/>
        <meta property="og:image:height" content={siteSettings.shareImageHeight}/>

        <meta name="twitter:title" content={metaTitle}/>
        <meta name="twitter:description" content={siteSettings.siteDescription}/>
        <meta name="twitter:url" content={siteSettings.siteUrl}/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content={siteSettings.image}/>

        <meta name="google-site-verification" content="_15yVB2JdnGYJioiAfQOr1Ic_rwEdE34nZIg8EKsCTk" />

        <link rel="canonical" href={siteSettings.siteUrl}/>

        <script defer data-domain="gdb-repair.com" src="https://plausible.io/js/plausible.js"/>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "ProfessionalService",
            "name": "GDB Garage Door Repair",
            "image": "https://www.gdb-repair.com/main.webp",
            "@id": "",
            "url": "https://www.gdb-repair.com/",
            "telephone": "773-956-1198",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "1043 S York Rd Ste",
              "addressLocality": "Bensenville",
              "addressRegion": "IL",
              "postalCode": "60106",
              "addressCountry": "US"
            },
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": 41.933984,
              "longitude": -87.93830559999999
            },
            "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
              ],
              "opens": "07:00",
              "closes": "22:00"
            },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Saturday",
                "Sunday"
              ],
              "opens": "07:00",
              "closes": "20:00"
            }],
            "sameAs": [
              "https://www.facebook.com/GDB.GarageDoorRepair",
              "https://www.instagram.com/gdbgaragedoorrepair/",
              "https://twitter.com/DoorGdb",
              "https://www.youtube.com/channel/UCK8N5Pkvm2G_RarsfRfPjYQ",
              "https://www.gdb-repair.com/"
            ] 
          })}
        </script>
      </Helmet>

      <HeaderComponent/>
      <main className="service-area">
        <div className="top-row relative">
          <Img fluid={fluidTopImgSource} objectFit="cover" className="image" alt="Map"/>
          <div className="description mx-auto">
            <div className="container h-full mx-auto flex flex-col gap-4 items-center justify-center px-5">
              <h1 className="service-area-title text-center">{pageContext.h1Title}</h1>
              <p className="text-center">Professional garage door repair and installation services
                near {pageContext.title}.
                Call us if you need a garage door repair service</p>
              <a className="gdb-btn primary wide flex w-6" href={siteSettings.phoneNumberLink}>Call us</a>
            </div>
          </div>
        </div>
        <div className="services">
          <div className="container mx-auto p-5">
          <h2 className="text-center mb-5">{pageContext.title} garage door repair and installation</h2>
            <div className="flex flex-col md:flex-row justify-center gap-5 items-center">
              <div className="guaranty-block order-2 md:order-1">
                <Img fluid={starGoogleImgSource} objectFit="contain" className="image" alt="starGoogle"/>
                <Img fluid={googleGuaranteedImgSource} objectFit="contain" className="image" alt="googleGuaranteed"/>
                <Img fluid={yelpImgSource} objectFit="contain" className="image" alt="yelp"/>
              </div>
              <div className="flex flex-col gap-5 md:w-1/2 prices-parent order-1 md:order-2">
                
                {/* {pageContext.services.map(service => (
                  <div key={service} className="service">{service}</div>
                ))} */}
                <div className="price-block px-5">
                  <div className="mb-1 text-center mt-5">3.5% credit card processing fee</div>
                  <div className="mb-3"><Img className="text-center" fluid={paymentImgSource} alt="payment"/></div>
                  <div>
                    <div class="price_table_wrap">
                      <h3 class="column_heading text-center"><span class="d-block">Garage door service prices</span></h3>
                      <ul class="service_price_table">
                        <li>
                            <a href="#" title="Garage Door Installation in Elgin IL">
                                <div class="service_type"><span>Service Call <em>(deducted from total amount)</em></span></div>
                                <div class="service_price"><div class="wrap"><span>fixed price</span><span class="price_mark">$29</span></div></div></a>
                        </li>
                        <li>
                            <a href="#" title="Garage Door Tune-Up in Elgin IL">
                                <div class="service_type"><span>Garage Door Tune-Up</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$89<span>-</span>$149</span></div></div></a>
                        </li>

                        <li><a href="#" title="Garage Door Spring Repair in Elgin IL">
                                <div class="service_type"><span>Garage Door Spring Repair <em>(labor)</em></span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$119<span>-</span>$149</span></div></div></a>
                        </li>

                        <li><a href="#" title="Garage Door Spring Repair in Elgin IL">
                                <div class="service_type"><span>Garage Door Springs <em>(parts)</em></span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$89<span>-</span>$149</span></div></div></a>
                        </li>

                        <li><a href="#" title="Garage Door Remote and Keypad Programming in Elgin IL">
                                <div class="service_type"><span>Remote or Keypad Programming</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$89<span>-</span>$119</span></div></div></a>
                        </li>

                        <li><a href="#" title="Garage Door Cable Repair in Elgin IL">
                                <div class="service_type"><span>Garage Door Cable Repair</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$119<span>-</span>$179</span></div></div></a>
                        </li>

                        <li><a href="#" title="Basic Garage Door Repair in Elgin IL">
                                <div class="service_type"><span>Basic Garage Door Repair</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$119<span>-</span>$149</span></div></div></a>
                        </li>

                        <li><a href="#" title="Basic Garage Door Repair in Elgin IL">
                                <div class="service_type"><span>Sensors Repair/ Replacement</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$119<span>-</span>$199</span></div></div></a>
                        </li>

                        <li><a href="#" title="Basic Garage Door Repair in Elgin IL">
                                <div class="service_type"><span>Garage Door Off-Track Repair</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$119<span>-</span>$199</span></div></div></a>
                        </li>

                        <li><a href="#" title="Garage Door Installation in Elgin IL">
                                <div class="service_type"><span>New Garage Door Installation</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$499<span>-</span>$699</span></div></div></a>
                        </li>

                        <li><a href="#" title="Garage Door Opener Repair Services in Elgin IL">
                                <div class="service_type"><span>Garage Door Opener Installation</span></div>
                                <div class="service_price"><div class="wrap"><span>price range</span><span class="price_mark">$399<span>-</span>$649</span></div></div></a>
                        </li>
                      </ul>
                      <p class="mb-0 fs_11 d-flex py-1 ps-2 lh-1 text-start"><b class="fw_400 me-1">Note:</b> Our service prices vary depending on the particular size and type of your garage door.</p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </main>
      <FooterComponent/>
    </div>
  )
}

export default ServiceAreaPage

export const query = graphql`
    query {
        top: file(relativePath: { eq: "service-area.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        payment: file(relativePath: { eq: "payment.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        starGoogle: file(relativePath: { eq: "5StarGoogle.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        googleGuaranteed: file(relativePath: { eq: "google-guaranteed.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        yelp: file(relativePath: { eq: "yelp.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
